import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import CssBaseline from "@material-ui/core/CssBaseline";
import { Wrapper } from "./styles";

const MainLayout = ({ children }) => {
  return (
    <Wrapper container component="main" justify="center">
      <CssBaseline />

      {children}
    </Wrapper>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default connect()(MainLayout);
