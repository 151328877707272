/* eslint-disable react/jsx-max-depth */
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { Title, Description, PopupDialog, IconGroup } from "./styles";

const Popup = ({ children, title, description, open }) => {
  const [close, setClose] = useState(true);

  return (
    <>
      <Dialog maxWidth="sm" open={open ? close : open}>
        <PopupDialog>
          <IconGroup aria-label="close" onClick={() => setClose(false)}>
            <CloseIcon />
          </IconGroup>

          <Title>{title}</Title>
          <Description>{description}</Description>

          {children}
        </PopupDialog>

        <DialogActions>
          <Button onClick={() => setClose(false)} color="secondary">
            Tamam
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Popup.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default connect()(Popup);
