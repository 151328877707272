import styled from "styled-components";
import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";

export const IconGroup = styled(IconButton)`
  position: absolute !important;
  top: 5px;
  right: 5px;

  @media (max-width: 959px) {
    top: 0;
    right: 0;
  }
`;

export const Title = styled(Box)`
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.3px;
  font-weight: 800;
  margin-bottom: 0;
  margin-top: 17px;

  @media (max-width: 959px) {
    margin-bottom: 15px;
    font-size: 25px;
  }
`;

export const PopupDialog = styled(DialogContent)`
  && {
    padding: 0 55px 5px;
  }

  @media (max-width: 959px) {
    && {
      padding: 30px 30px 0;
    }
  }
`;

export const Description = styled(Box)`
  font-style: italic;
  color: #ee5a36;
  font-size: 15px;
  margin-top: 15px;
  text-align: center;

  @media (max-width: 959px) {
    font-size: 14px;
  }
`;
