import {
  SAVE_DRIVER_START,
  SAVE_DRIVER_SUCCESS,
  SAVE_DRIVER_FAILURE,
} from "./types";
import API from "../../utils/api";

export const saveDriverStartAction = () => ({ type: SAVE_DRIVER_START });

export const saveDriverSuccessAction = () => ({
  type: SAVE_DRIVER_SUCCESS,
});
export const saveDriverFailureAction = (error) => ({
  type: SAVE_DRIVER_FAILURE,
  payload: error,
});

export const saveDriver = (values) => (dispatch) => {
  dispatch(saveDriverStartAction());

  const finalValues = values;
  const phoneNumber = values.phone_number;

  finalValues.phone_number = phoneNumber.replace(/[ ()]/g, "");

  API.post(`drivers/`, { ...finalValues })
    .then(() => {
      dispatch(saveDriverSuccessAction());
    })
    .catch((error) => {
      dispatch(saveDriverFailureAction(error));
    });
};
