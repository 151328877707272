import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import DriverForm from "./containers/DriverForm";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Servis Sürücüleri İçin İş Fırsatları</title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;0,800;1,400&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <DriverForm />
    </>
  );
}

export default App;
