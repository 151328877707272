import {
  SAVE_DRIVER_START,
  SAVE_DRIVER_SUCCESS,
  SAVE_DRIVER_FAILURE,
} from "./types";

const initialState = {
  driverFormError: false,
  isSavingDriver: false,
  isDriverSaved: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_DRIVER_START:
      return {
        ...state,
        isSavingDriver: true,
      };
    case SAVE_DRIVER_SUCCESS:
      return {
        ...state,
        isSavingDriver: false,
        isDriverSaved: true,
        driverFormError: false,
      };
    case SAVE_DRIVER_FAILURE:
      return {
        ...state,
        isSavingDriver: false,
        isDriverSaved: false,
        driverFormError: action.payload.message,
      };
    default:
      return state;
  }
}
