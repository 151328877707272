import styled from "styled-components";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";

export const PageTitle = styled(Box)`
  font-size: 40px;
  letter-spacing: 0.3px;
  font-weight: 800;
  margin-bottom: 0;

  @media (max-width: 959px) {
    margin-bottom: 20px;
    line-height: 1.2em;
    font-size: 33px;
  }
`;

export const PageDesc = styled(Box)`
  font-style: italic;
  color: #ee5a36;
  font-size: 19px;
  margin-top: 5px;

  @media (max-width: 959px) {
    font-size: 16px;
  }
`;

export const ToolTitle = styled(Box)``;

export const DriverContainer = styled(Grid)`
  padding-top: 80px;

  @media (max-width: 959px) {
    padding: 25px 35px 45px;
    border-top: 43px solid #ef623b;
  }
`;

export const FormField = styled(FormControl)`
  width: 100%;
`;

export const ToolbarContainer = styled(Toolbar)`
  justify-content: center;
`;

export const SubmitButton = styled(Button)`
  && {
    display: block;
    margin-top: 20px;
    padding: 7px 34px;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 1px;
  }

  @media (max-width: 959px) {
    && {
      width: 100%;
      padding: 7px;
      font-size: 15px;
    }
  }
`;

export const AppBarFill = styled(AppBar)`
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.01),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
`;
