import axios from "axios";
import config from "../config";

const API = axios.create({
  baseURL: config.apiUrl,
});

API.interceptors.response.use(({ data }) => data);

export default API;
