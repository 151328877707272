/* eslint-disable react/jsx-max-depth */
import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";

import DateFnsUtils from "@date-io/date-fns";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

import Input from "@material-ui/core/Input";
import MaskedInput from "react-text-mask";

import trLocale from "date-fns/locale/tr";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import {
  PageTitle,
  PageDesc,
  FormField,
  SubmitButton,
  DriverContainer,
  ToolbarContainer,
  ToolTitle,
  AppBarFill,
} from "./styles";

import driverFillData from "./data/driver.json";

import Popup from "../../components/Popup";
import MainLayout from "../../layouts/MainLayout";

import { saveDriver } from "../../store/driver/actions";

function TextMaskCustom({ inputRef, ...other }) {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /5/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
      ]}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const DriverForm = () => {
  const {
    driver: { isDriverSaved, driverFormError },
  } = useSelector(({ driver }) => ({
    driver,
  }));

  const dispatch = useDispatch();

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    birth_date: new Date(),
    has_smartphone: "",
    has_shuttle: "",
    shuttle_brand: "",
    shuttle_model: "",
    shuttle_year: "",
    phone_number: "",
    county: "",
  });

  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    birth_date: false,
    has_smartphone: false,
    has_shuttle: false,
    phone_number: false,
    county: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let finalValue = value;

    setErrors({ ...errors, [name]: false });
    if (name === "has_shuttle" || name === "has_smartphone") {
      finalValue = value === "true";
    }

    setValues({ ...values, [name]: finalValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const finalValues = Object.entries(values);

    let finalErrors = errors;

    let currentDate = new Date();
    currentDate = `${currentDate.getFullYear()}-${`0${
      currentDate.getMonth() + 1
    }`.slice(-2)}-${currentDate.getDate()}`;

    finalValues.map((value) => {
      let birthDate = value[0] === "birth_date" ? value[1] : false;

      if (birthDate) {
        birthDate = `${birthDate.getFullYear()}-${`0${
          birthDate.getMonth() + 1
        }`.slice(-2)}-${birthDate.getDate()}`;
      }

      if (
        (value[1] === "" && !value[0].includes("shuttle_")) ||
        currentDate === birthDate
      ) {
        finalErrors = { ...finalErrors, [value[0]]: true };
      }
    });

    setErrors(finalErrors);

    if (Object.keys(errors).every((e) => finalErrors[e] === false)) {
      const newBirthDate =
        values.birth_date instanceof Date
          ? `${values.birth_date.getFullYear()}-${`0${
              values.birth_date.getMonth() + 1
            }`.slice(-2)}-${values.birth_date.getDate()}`
          : values.birth_date;
      dispatch(saveDriver({ ...values, birth_date: newBirthDate }));
    }
  };

  return (
    <MainLayout>
      <AppBarFill position="absolute" color="inherit">
        <ToolbarContainer>
          <LibraryBooksIcon color="primary" />

          <ToolTitle component="h2" ml={1}>
            Sürücü İlanları
          </ToolTitle>
        </ToolbarContainer>
      </AppBarFill>

      <DriverContainer item md={7} xs={12} sm={12}>
        <Box mb={{ xs: 5, sm: 9 }} textAlign="center" mt={{ xs: 4, sm: 7 }}>
          <PageTitle component="h1">
            Servis sürücüleri için iş fırsatları
          </PageTitle>

          <PageDesc component="p">
            İstanbul'da yüzlerce hat üzerindeki iş imkanlarından SMS ile anında
            haberdar ol!
          </PageDesc>
        </Box>

        <form onSubmit={handleSubmit}>
          <Box
            mb={4}
            justifyContent="space-between"
            display={{ xs: "block", sm: "flex" }}
          >
            <Grid item sm={5}>
              <Box
                justifyContent="space-between"
                display={{ xs: "block", sm: "flex" }}
              >
                <Grid item xs={12} sm={5}>
                  <Box mb={{ xs: 3, sm: 0 }}>
                    <TextField
                      label="Ad"
                      name="first_name"
                      autoFocus
                      fullWidth
                      onChange={handleInputChange}
                      value={values.first_name}
                      helperText="Ör. Ahmet"
                      error={errors.first_name}
                      disabled={isDriverSaved}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <Box mb={{ xs: 5, sm: 0 }}>
                    <TextField
                      label="Soyad"
                      name="last_name"
                      fullWidth
                      onChange={handleInputChange}
                      value={values.last_name}
                      error={errors.last_name}
                      helperText="Ör. Yılmaz"
                      disabled={isDriverSaved}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormField>
                <InputLabel htmlFor="phoneNumber">Telefon Numarası</InputLabel>

                <Input
                  name="phone_number"
                  inputComponent={TextMaskCustom}
                  onChange={handleInputChange}
                  value={values.phone_number}
                  error={errors.phone_number}
                  type="tel"
                  label="(550) 500-0000"
                  disabled={isDriverSaved}
                />
              </FormField>
            </Grid>
          </Box>

          <Box
            mt={{ xs: 0, sm: 6 }}
            mb={6}
            justifyContent="space-between"
            display={{ xs: "block", sm: "flex" }}
          >
            <Grid item sm={5} xs={12}>
              <Box mb={{ xs: 3, sm: 0 }} mt={{ xs: 4, sm: 0 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
                  <KeyboardDatePicker
                    disableFuture
                    disableToolbar
                    openTo="year"
                    variant="inline"
                    format="dd/MM/yyyy"
                    label="Doğum Tarihi"
                    views={["year", "month", "date"]}
                    onChange={(value) => {
                      setValues({ ...values, birth_date: value });
                      setErrors({ ...errors, birth_date: false });
                    }}
                    name="birth_date"
                    value={values.birth_date}
                    error={errors.birth_date}
                    fullWidth
                    helperText="GG/AA/YYYY"
                    disabled={isDriverSaved}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormField>
                <InputLabel id="district-label">Yaşadığınız İlçe</InputLabel>

                <Select
                  labelId="district-label"
                  name="county"
                  onChange={handleInputChange}
                  value={values.county}
                  error={errors.county}
                  disabled={isDriverSaved}
                >
                  {driverFillData.form.districts.map((district) => (
                    <MenuItem value={district} key={district}>
                      {district}
                    </MenuItem>
                  ))}
                </Select>
              </FormField>
            </Grid>
          </Box>

          <Box
            mb={3}
            justifyContent="space-between"
            display={{ xs: "block", sm: "flex" }}
          >
            <Grid item sm={5} xs={12}>
              <Box mb={{ xs: 4, sm: 0 }}>
                <FormField
                  component="fieldset"
                  error={errors.has_smartphone}
                  disabled={isDriverSaved}
                >
                  <FormLabel component="legend">
                    Akıllı telefonunuz var mı?
                  </FormLabel>

                  <RadioGroup
                    name="has_smartphone"
                    row
                    onChange={handleInputChange}
                    value={`${values.has_smartphone}`}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" />}
                      label="Evet"
                    />

                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" />}
                      label="Hayır"
                    />
                  </RadioGroup>
                </FormField>
              </Box>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormField
                component="fieldset"
                error={errors.has_shuttle}
                disabled={isDriverSaved}
              >
                <FormLabel component="legend">
                  Kendinize ait servis aracınız var mı?
                </FormLabel>

                <RadioGroup
                  name="has_shuttle"
                  row
                  onChange={handleInputChange}
                  value={`${values.has_shuttle}`}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Evet"
                  />

                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="Hayır"
                  />
                </RadioGroup>
              </FormField>
            </Grid>
          </Box>

          {values.has_shuttle > 0 && (
            <>
              <Box
                mb={5}
                justifyContent="space-between"
                display={{ xs: "block", sm: "flex" }}
              >
                <Grid item sm={5} xs={12}>
                  <Box mb={{ xs: 3, sm: 0 }}>
                    <FormField disabled={isDriverSaved}>
                      <InputLabel id="shuttleBrand-label">
                        Araç Markası
                      </InputLabel>

                      <Select
                        labelId="shuttleBrand-label"
                        name="shuttle_brand"
                        onChange={handleInputChange}
                        value={values.shuttle_brand}
                      >
                        {driverFillData.form.shuttle.brands.map((brand) => (
                          <MenuItem value={brand} key={brand}>
                            {brand}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormField>
                  </Box>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Araç Modeli"
                    name="shuttle_model"
                    fullWidth
                    onChange={handleInputChange}
                    value={values.shuttle_model}
                    disabled={isDriverSaved}
                  />
                </Grid>
              </Box>

              <Box
                justifyContent="space-between"
                display={{ xs: "block", sm: "flex" }}
              >
                <Grid item sm={5} xs={12}>
                  <FormField component="fieldset" disabled={isDriverSaved}>
                    <FormLabel component="legend">
                      Araç model yılı 2013 ve üstü mü?
                    </FormLabel>

                    <RadioGroup
                      name="shuttle_year"
                      row
                      onChange={handleInputChange}
                      value={values.shuttle_year}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label="Evet"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label="Hayır"
                      />
                    </RadioGroup>
                  </FormField>
                </Grid>
              </Box>
            </>
          )}

          <Box
            md={12}
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            display="flex"
            sm={3}
            mb={{ xs: 2, sm: 7 }}
            mt={2}
          >
            <SubmitButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={isDriverSaved}
            >
              HEMEN ÜCRETSİZ KAYIT OL
            </SubmitButton>
            {driverFormError && (
              <Box color="error.main" fontWeight={600} mt={2}>
                Sistemde girilen telefon numarasına ait bir kayıt bulunmaktadır
              </Box>
            )}
          </Box>
        </form>
      </DriverContainer>

      <Popup
        title="Teşekkür ederiz!"
        description="Bilgileriniz tarafımıza ulaşmıştır, iş imkanları bilgileriniz doğrultusunda
          size SMS olarak iletilecektir. Bununla birlikte sizi iş fırsatları için arıyor olacağız."
        open={isDriverSaved}
      />
    </MainLayout>
  );
};

DriverForm.propTypes = {};

export default connect()(DriverForm);
