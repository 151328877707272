import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { trTR } from "@material-ui/core/locale";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

const THEME = createMuiTheme(
  {
    typography: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    palette: {
      primary: {
        main: "#ee5a36",
      },
      secondary: {
        main: "#1f1324",
      },
    },
  },
  trTR
);

ReactDOM.render(
  <MuiThemeProvider theme={THEME}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
